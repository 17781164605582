import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ImpressumPage = () => (
  <Layout>
    <SEO title="Impressum" keywords={[`gatsby`, `application`, `react`]} />
    <div className="container margin-80-top">
      <div className="row">
        <div className="col-12">
          <h1>Impressum</h1>
          <p className="font-weight-bold margin-40-top">
            Angaben gemäß § 5 TMG, der DL‐InfoV und § 55 Abs. 2 RStV:
          </p>
          <p>
            Kanz & Sagolla GbR (K & S Rechtsanwälte)
            <br /> Dresdener Straße 11
            <br /> 65474 Bischofsheim
            <br /> info@ksrechtsanwaelte.de
            <br /> Telefon +49 (0) 06144 / 4029610
            <br /> Telefax +49 (0) 06144 / 4029619
          </p>
          <p>
            Vertretungsberechtigte Gesellschafter:
            <br />
            Rechtsanwalt Simon Kanz
            <br />
            Rechtsanwältin Beatrice Sagolla
            <br />
          </p>
          <p className="font-weight-bold">Steuernummer</p>
          <p>021 333 60 341</p>
          <p className="font-weight-bold">Berufshaftpflichtversicherung</p>
          <p>
            Allianz Versicherungs-Aktiengesellschaft
            <br />
            Königinstr. 28
            <br />
            80802 München
            <br />
          </p>
          <p>
            Inhaltlich Verantwortlicher gemäß § 55 Absatz 2 RStV:
            <br />
            Rechtsanwalt Simon Kanz
            <br />
            Rechtsanwältin Beatrice Sagolla
            <br />
          </p>
          <p>
            Wir führen die Berufsbezeichnung „Rechtsanwältin“ bzw.
            „Rechtsanwalt”, die uns nach deutschem Recht verliehen worden ist.
          </p>
          <p>
            Soweit diese Internetseite Inhalte in Ausübung des Berufes der in
            der Sozietät tätigen Rechtsanwältinnen und Rechtsanwälte enthält,
            gilt das Folgende:
            <br />
            Die in der Sozietät tätigen Rechtsanwältinnen und Rechtsanwälte sind
            Mitglieder der Rechtsanwaltskammer Frankfurt am Main, Bockenheimer
            Anlage 36, 60322 Frankfurt am Main.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default ImpressumPage
